import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import createVuetifyInstance from "./plugins/vuetify";
import { createProvider } from "./vue-apollo";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import * as inputValidators from "./inputValidators";
// Globally Registered Base Components
import "./components/globalComponent.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { VTooltip } from "v-tooltip";
Vue.config.productionTip = false;
// component registration
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.directive("tooltip", VTooltip);
Vue.component("VuePerfectScrollbar", VuePerfectScrollbar);
window.$ = window.jQuery = require("jquery");
// queries import
import { GET_ORG_COLOR_THEME } from "./graphql/LoginQueries";

async function getThemeColors() {
  let orgCode = localStorage.getItem("orgCode");
  if (!orgCode) {
    try {
      let url = window.location.href;
      let index = url.indexOf("?");
      if (index > -1) {
        let decodeParam = url.substr(index + 1);
        let leftUrl = url.substr(0, index);
        let buff = Buffer.from(decodeParam, "base64");
        let decodedData = buff.toString("ascii");
        let url_string = leftUrl + "?" + decodedData;
        url_string = new URL(url_string);
        localStorage.clear();
        if (url_string.searchParams) {
          orgCode = url_string.searchParams.get("code");
          localStorage.setItem("orgCode", orgCode);
        }
      }
    } catch {
      orgCode = "";
      localStorage.setItem("orgCode", orgCode);
    }
  }
  try {
    let client = createProvider().clients.apolloClientD;
    const response = await client.query({ query: GET_ORG_COLOR_THEME });
    let data = response.data;
    if (
      data &&
      data.customColorPicker &&
      data.customColorPicker.colorResult &&
      data.customColorPicker.colorResult.length > 0
    ) {
      const colors = data.customColorPicker.colorResult[0];
      localStorage.setItem("colorThemeObject", JSON.stringify(colors));
      return colors;
    }
  } catch (error) {
    const colors = {
      Primary_Color: "#260029",
      Secondary_Color: "#EC407A",
    };
    localStorage.setItem("colorThemeObject", JSON.stringify(colors));
    return colors;
  }
}

async function initializeApp() {
  const colors = await getThemeColors();
  const vuetify = await createVuetifyInstance(colors);

  new Vue({
    inputValidators,
    router,
    store,
    vuetify,
    apolloProvider: createProvider(),
    render: (h) => h(App),
  }).$mount("#app");
}
initializeApp();
