import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import VueApollo from "vue-apollo";
import Vue from "vue";
import store from "./store";
import config from "./config";

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`)
    );
  } else if (networkError) {
    console.log("[Network error]:", networkError);
    const context = operation.getContext();
    if (context && context.response) {
      const { status } = context.response;
      // on Un-authorization clear user session and redirect to auth
      if (status === 401) {
        store.dispatch("logoutUser");
      }
    }
  }
});

//configure the auth token
const authHeader = setContext((_, { headers }) => {
  const org_code = localStorage.getItem("orgCode");
  const d_code = localStorage.getItem("d_code");
  const b_code = localStorage.getItem("b_code");
  return {
    headers: {
      ...headers,
      org_code: org_code ? org_code : store.getters.orgCode,
      additional_headers: JSON.stringify({
        d_code: d_code,
        b_code: b_code,
        org_code: org_code,
      }),
    },
  };
});

const httpLinkA = new HttpLink({
  uri: config.graphql_endpoint.onboardBE,
});

const httpLinkB = new HttpLink({
  uri: config.graphql_endpoint.onboardRead,
});

const httpLinkC = new HttpLink({
  uri: config.graphql_endpoint.onboardWrite,
});

const httpLinkD = new HttpLink({
  uri: config.graphql_endpoint.atsExternal,
});

//composing all configuration in link using apollolink
const linkA = ApolloLink.from([errorLink, httpLinkA]);
const linkB = ApolloLink.from([authHeader, errorLink, httpLinkB]);
const linkC = ApolloLink.from([authHeader, errorLink, httpLinkC]);
const linkD = ApolloLink.from([authHeader, errorLink, httpLinkD]);

// Create the two apollo client instance
const apolloClientA = new ApolloClient({
  link: linkA,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production", //enable dev tool only on development
});

const apolloClientB = new ApolloClient({
  link: linkB,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production", //enable dev tool only on development
});

const apolloClientC = new ApolloClient({
  link: linkC,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production", //enable dev tool only on development
});

const apolloClientD = new ApolloClient({
  link: linkD,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production", //enable dev tool only on development
});

function apolloConfig() {
  return () => {
    // Install the Vue plugin
    Vue.use(VueApollo);
    // Create vue apollo provider
    const apolloProvider = new VueApollo({
      clients: {
        apolloClientA,
        apolloClientB,
        apolloClientC,
        apolloClientD,
      },
      defaultClient: apolloClientA,
    });
    return apolloProvider;
  };
}

export const createProvider = apolloConfig();
