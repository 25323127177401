<template>
  <div class="full-height">
    <LayoutCheck
      v-if="!isValidLayout"
      calledFrom="unAuth"
      @valid-layout="isValidLayout = true"
      @is-vendor="isVendor = true"
    ></LayoutCheck>
    <Login v-else :isVendor="isVendor"></Login>
  </div>
</template>

<script>
import Login from "@/views/login/Login.vue";
import LayoutCheck from "./LayoutCheck.vue";

export default {
  name: "UnAuthenticateLayout",

  components: { Login, LayoutCheck },

  data: () => ({
    isValidLayout: false,
    isVendor: false,
  }),
};
</script>

<style lang="css">
@import url("../assets/styles/commonClasses.css");
</style>
