<template>
  <v-snackbar
    id="app_snackbar"
    :timeout="5000"
    :value="showSnackBar"
    absolute
    top
    :color="snackBarType"
    centered
    outlined
    class="custom-snackbar"
  >
    <div
      class="d-flex align-center cursor_pointer snack-content"
      @click="showMessageBar = false"
    >
      <div>
        <!-- only for success the icon name gets change -->
        <v-icon :color="snackBarType" size="30" class="mr-2">{{
          snackbarIcon
        }}</v-icon>

        {{ snackBarMsg }}
      </div>
      <v-icon
        :color="snackBarType"
        size="30"
        class="mr-2"
        @click="showMessageBar = false"
      >
        close
      </v-icon>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: "AppSnackBar",
  props: {
    showSnackBar: {
      type: Boolean,
      required: true,
    },
    snackBarMsg: {
      type: String,
      required: true,
    },
    snackBarType: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      showMessageBar: false,
    };
  },
  computed: {
    //to know whether browser support webp or not
    isBrowserSupportWebp() {
      return this.$store.state.isWebpSupport;
    },
    snackbarIcon() {
      switch (this.snackBarType) {
        case "success":
          return "check_circle";
        case "warning":
          return "error";
        case "error":
          return "cancel";
        default:
          return "info";
      }
    },
  },

  watch: {
    showMessageBar(val) {
      !val ? this.$emit("close-snack-bar") : "";
    },
  },
  mounted() {
    this.showMessageBar = this.showSnackBar;
    setTimeout(() => {
      this.showMessageBar = false;
    }, 5000);
  },
};
</script>

<style lang="css">
.custom-snackbar {
  max-width: 100% !important;
  min-height: 48px !important;
  width: 100% !important;
  z-index: 6 !important;
}

.snack-content {
  font-size: 1.2em;
  font-weight: 500;
  justify-content: space-between;
}
#app_snackbar > .v-snack__wrapper {
  width: 94.5% !important;
  min-height: 55px !important;
  border-radius: 1px;
  max-width: none !important;
  position: fixed !important;
  margin: 0px 0px 3px 3px !important;
  background-color: white !important;
  box-shadow: 0px 1px 15px rgba(15, 84, 136, 0.14) !important;
  border: 2px solid !important;
}

@media screen and (max-width: 1700px) {
  #app_snackbar > .v-snack__wrapper {
    width: 93% !important;
  }
}
@media screen and (max-width: 1500px) {
  #app_snackbar > .v-snack__wrapper {
    width: 92% !important;
  }
}

@media screen and (max-width: 1260px) {
  #app_snackbar > .v-snack__wrapper {
    width: 100% !important;
    margin-left: 0px !important;
  }
}
</style>
