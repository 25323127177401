export default {
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
    state.isMobileWindowSize = width > 600 ? false : true;
  },
  //mutation to close snackbar and set it to default values
  CLOSE_SNACKBAR(state) {
    state.showSnackbar = false;
    state.snackBarMessage = "";
    state.snackBarType = "warning";
  },
  //mutation to show snackbar to present any error or success messages
  OPEN_SNACKBAR(state, snackbarData) {
    state.showSnackbar = snackbarData.isOpen;
    state.snackBarMessage = snackbarData.message;
    state.snackBarType = snackbarData.type;
  },
};
