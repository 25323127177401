<template>
  <v-app style="background: #fbfbfb">
    <v-main>
      <AppSnackBar
        v-if="snackbarData.isOpen"
        :show-snack-bar="snackbarData.isOpen"
        :snack-bar-msg="snackbarData.message"
        :image-name="snackbarData.image"
        :snack-bar-type="snackbarData.type"
        @close-snack-bar="closeSnackbarAlert()"
      >
      </AppSnackBar>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({}),

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  },

  computed: {
    //show/hide snackbar based on store data
    snackbarData() {
      return this.$store.getters.getSnackbarData;
    },
  },

  methods: {
    //function to close the snackbar alert
    closeSnackbarAlert() {
      this.$store.commit("CLOSE_SNACKBAR");
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
    },
  },
};
</script>
