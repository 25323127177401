const prod_baseurl = "https://onboardapi.hrapp.co";
const prod_baseurl_hrapp = "https://api.hrapp.co";
const production = {
  domain: "hrapp",
  graphql_endpoint: {
    onboardBE: prod_baseurl + "/graphql",
    onboardRead: prod_baseurl + "/rographql",
    onboardWrite: prod_baseurl + "/wographql",
    atsExternal: prod_baseurl_hrapp + "/ats/external",
  },
};

let configuration;
if (process.env.NODE_ENV === "production") {
  configuration = production;
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...configuration,
};
