import gql from "graphql-tag";
// ===============
// Queries
// ===============
export const SIGN_IN = gql`
  query pinAuth($Org_Code: String!, $Url_Hash: String!, $Pin: Int!) {
    pinAuth(Org_Code: $Org_Code, Url_Hash: $Url_Hash, Pin: $Pin) {
      message
      countryCode
      serviceProviderId
    }
  }
`;

export const SIGN_IN_VENDOR = gql`
  query authenticatePin($urlHash: String!, $pin: Int!) {
    authenticatePin(urlHash: $urlHash, pin: $pin) {
      errorCode
      message
    }
  }
`;

export const URL_EXPIRATION = gql`
  query urlStatus($Org_Code: String!, $Url_Hash: String!) {
    checkUrlStatus(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      message
      __typename
    }
    __typename
  }
`;

export const VENDOR_URL_EXPIRATION = gql`
  query checkUrlStatus($urlHash: String!) {
    checkUrlStatus(urlHash: $urlHash) {
      errorCode
      message
      vendorId
    }
  }
`;

export const GET_ORG_COLOR_THEME = gql`
  query customColorPicker {
    customColorPicker {
      errorCode
      message
      colorResult {
        Primary_Color
        Secondary_Color
        Hover_Color
        Table_Header_Color
        Table_Header_Text_Color
      }
    }
  }
`;
