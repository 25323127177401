import {
  UPLOAD_DOCUMENT,
  RETRIEVE_DOCUMENT,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
  GET_PRESIGNED_URL,
  RETRIEVE_IMAGE,
  DELETE_IMAGE,
} from "../graphql/OnboardingQueries";
import { createProvider } from "../vue-apollo";
import axios from "axios";

export default {
  // restore store to default value on logout
  logoutUser() {
    let urlParam = localStorage.getItem("urlParam");
    if (urlParam) {
      window.location.href = "/" + urlParam;
    } else {
      window.location.href = "/";
    }
  },

  s3FileUploadRetrieveAction({ commit }, payload) {
    const {
      clients: { apolloClientD },
    } = createProvider();
    let errMsg = payload.action === "upload" ? "uploading" : "retrieving";
    errMsg =
      "Something went wrong while " +
      errMsg +
      " the file contents. Please try after some time.";
    return new Promise((resolve, reject) => {
      apolloClientD
        .mutate({
          mutation: GET_PRESIGNED_URL,
          variables: payload,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          if (response.data.getPresignedUrl) {
            const { presignedUrl, s3DocumentDetails } =
              response.data.getPresignedUrl;
            if (presignedUrl) {
              if (payload.action === "upload") {
                axios
                  .put(presignedUrl, payload.fileContent)
                  .then(() => {
                    resolve();
                  })
                  .catch(() => {
                    let snackbarData = {
                      isOpen: true,
                      message: errMsg,
                      type: "warning",
                    };
                    commit("OPEN_SNACKBAR", snackbarData);
                    reject();
                  });
              } else {
                resolve(presignedUrl);
              }
            } else {
              if (
                (payload.action === "getdata" ||
                  payload.action === "getrawdata") &&
                s3DocumentDetails
              ) {
                resolve(s3DocumentDetails);
              } else {
                let snackbarData = {
                  isOpen: true,
                  message: errMsg,
                  type: "warning",
                };
                commit("OPEN_SNACKBAR", snackbarData);
                reject();
              }
            }
          } else {
            let snackbarData = {
              isOpen: true,
              message: errMsg,
              type: "warning",
            };
            commit("OPEN_SNACKBAR", snackbarData);
            reject();
          }
        })
        .catch(() => {
          let snackbarData = {
            isOpen: true,
            message: errMsg,
            type: "warning",
          };
          commit("OPEN_SNACKBAR", snackbarData);
          reject();
        });
    });
  },

  uploadFile(state, payload) {
    const {
      clients: { apolloClientA },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientA
        .query({
          query: UPLOAD_DOCUMENT,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            File_Name: payload.fileName,
            File_Content: payload.fileContent,
            Category: payload.category,
          },
        })
        .then((res) => {
          resolve(res.data.fileUpload.fileName);
        })
        .catch(() => {
          reject("error");
        });
    });
  },

  retrieveFile(state, payload) {
    const {
      clients: { apolloClientA },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientA
        .query({
          query: RETRIEVE_DOCUMENT,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            File_Name: payload.fileName,
            Category: payload.category,
          },
        })
        .then((res) => {
          resolve(res.data.fileRetrieve.fileContent);
        })
        .catch(() => {
          reject("error");
        });
    });
  },

  retrieveImage(state, payload) {
    const {
      clients: { apolloClientD },
    } = createProvider();
    let orgCode = localStorage.getItem("orgCode")
      ? localStorage.getItem("orgCode")
      : this.$store.getters.orgCode;
    return new Promise((resolve, reject) => {
      apolloClientD
        .mutate({
          mutation: RETRIEVE_IMAGE,
          variables: {
            action: "getrawdata",
            fileName:
              "hrapp_upload/" + orgCode + "_tmp/images/" + payload.fileName,
            type: payload.type,
          },
        })
        .then((res) => {
          resolve(res.data.getPresignedUrl.s3DocumentDetails);
        })
        .catch(() => {
          reject("error");
        });
    });
  },
  deleteImage(state, payload) {
    const {
      clients: { apolloClientD },
    } = createProvider();
    let orgCode = localStorage.getItem("orgCode")
      ? localStorage.getItem("orgCode")
      : this.$store.getters.orgCode;
    return new Promise((resolve, reject) => {
      apolloClientD
        .mutate({
          mutation: DELETE_IMAGE,
          variables: {
            fileName:
              "hrapp_upload/" + orgCode + "_tmp/images/" + payload.fileName,
            type: payload.type,
          },
        })
        .then((res) => {
          resolve(res.data.deleteS3Files);
        })
        .catch(() => {
          reject("error");
        });
    });
  },

  replaceFile(state, payload) {
    const {
      clients: { apolloClientA },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientA
        .query({
          query: EDIT_DOCUMENT,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            File_Name: payload.fileName,
            Category: payload.category,
            New_File_Name: payload.newFileName,
            New_File_Content: payload.fileContent,
          },
        })
        .then((res) => {
          resolve(res.data.fileReplace.fileName);
        })
        .catch(() => {
          reject("error");
        });
    });
  },

  removeFile(state, payload) {
    const {
      clients: { apolloClientA },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientA
        .query({
          query: DELETE_DOCUMENT,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            File_Name: payload.fileName,
            Category: payload.category,
          },
        })
        .then(() => {
          resolve("success");
        })
        .catch(() => {
          reject("error");
        });
    });
  },
};
