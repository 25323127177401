export default {
  // get snackbar store data
  getSnackbarData(state) {
    let snackbarData = {
      isOpen: state.showSnackbar,
      message: state.snackBarMessage,
      type: state.snackBarType,
    };
    return snackbarData;
  },
};
