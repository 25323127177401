<template>
  <div class="full-height">
    <div v-if="isError" class="full-height">
      <v-row justify="center" class="full-height">
        <v-col
          cols="10"
          class="justify-center d-flex align-center"
          :class="isMobileView ? 'text-center flex-column' : ''"
        >
          <img src="../assets/images/login/error.png" alt="error in url" />
          <div
            class="pl-4 grey--text text--darken-2"
            :class="isMobileView ? 'mt-8' : ''"
          >
            <div class="text-h4">
              {{ errorMessage }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="full-height" v-if="isUrlExpired || isInvalidUrl">
      <v-row class="full-height">
        <v-col
          cols="12"
          class="justify-center d-flex align-center"
          :class="isMobileView ? 'text-center flex-column' : ''"
        >
          <img
            v-if="isInvalidUrl"
            src="../assets/images/login/invalidURL.png"
            alt="invalid url"
            width="300px"
          />
          <img
            v-else
            src="../assets/images/login/urlExpiry.png"
            alt="expired url"
          />
          <div
            class="grey--text text--darken-2"
            :class="isMobileView ? 'mt-8' : ''"
          >
            <div class="text-h4">
              {{ errorMessage }}
            </div>
            <div class="mt-4">
              Please provide a valid URL, which is provided by your organization
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="full-height" v-else-if="isSubmittedAlready">
      <v-row class="full-height">
        <v-col
          cols="12"
          class="justify-center d-flex align-center"
          :class="isMobileView ? 'text-center flex-column' : ''"
        >
          <img
            src="../assets/images/login/alreadySubmit.png"
            alt="already submitted"
            width="200px"
          />
          <div
            class="pl-6 grey--text text--darken-2"
            :class="isMobileView ? 'mt-8' : ''"
          >
            <div class="text-h4">
              {{ errorMessage }}
            </div>
            <div class="mt-4">
              Your submission is under review. Please contact
              <span class="font-weight-medium">{{ companyName }}</span> for more
              information
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <AppLoading v-if="isLoading"></AppLoading>
  </div>
</template>

<script>
import { URL_EXPIRATION, VENDOR_URL_EXPIRATION } from "@/graphql/LoginQueries";

export default {
  name: "LayoutCheck",

  props: {
    calledFrom: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isUrlExpired: false,
    isInvalidUrl: false,
    isError: false,
    isSubmittedAlready: false,
    errorMessage: "",
    isLoading: false,
    orgCode: "",
    urlHash: "",
    companyName: "",
    companyEmail: "",
    isVendorOnboard: false,
    vendorUrlHash: "",
    vendorId: "",
  }),

  computed: {
    // to check the device is mobile based on window size
    isMobileView() {
      return this.$store.state.isMobileWindowSize;
    },
  },

  mounted() {
    if (this.calledFrom === "auth") {
      this.orgCode = localStorage.getItem("orgCode");
      this.urlHash = localStorage.getItem("urlHash");
      this.vendorUrlHash = localStorage.getItem("vendorUrlHash");
      this.checkURLValidity();
    } else {
      let url = window.location.href;
      try {
        let index = url.indexOf("?");
        if (index > -1) {
          let decodeParam = url.substr(index + 1);
          let leftUrl = url.substr(0, index);
          let buff = Buffer.from(decodeParam, "base64");
          let decodedData = buff.toString("ascii");
          let url_string = leftUrl + "?" + decodedData;
          url_string = new URL(url_string);
          localStorage.clear();
          if (url_string.searchParams) {
            this.companyName = url_string.searchParams.get("companyName");
            this.orgCode = url_string.searchParams.get("code");
            this.urlHash = url_string.searchParams.get("identifier");
            let dCode = url_string.searchParams.get("d_code");
            let bCode = url_string.searchParams.get("b_code");
            let vendor = url_string.searchParams.get("isVendor");
            let country = url_string.searchParams.get("country");
            if (vendor == "yes") {
              this.isVendorOnboard = true;
              this.companyEmail = url_string.searchParams.get("companyEmail");
              localStorage.setItem("vendor", vendor);
              localStorage.setItem("vendorUrlHash", this.urlHash);
              this.vendorUrlHash = this.urlHash;
            }
            localStorage.setItem("urlHash", this.urlHash);
            localStorage.setItem("orgCode", this.orgCode);
            localStorage.setItem("companyName", this.companyName);
            localStorage.setItem("companyEmail", this.companyEmail);
            localStorage.setItem("urlParam", url_string.search);
            localStorage.setItem("authorization", "");
            localStorage.setItem("d_code", dCode);
            localStorage.setItem("b_code", bCode);
            //Have to change after backend
            if (country) {
              localStorage.setItem("country", country);
            } else {
              localStorage.setItem("country", "au");
            }
            this.checkURLValidity();
          } else {
            this.isInvalidUrl = true;
            this.errorMessage = "Invalid URL";
          }
        } else {
          this.isInvalidUrl = true;
          this.errorMessage = "Invalid URL";
        }
      } catch (e) {
        this.isInvalidUrl = true;
        this.errorMessage = "Invalid URL";
      }
    }
  },

  methods: {
    checkURLValidity() {
      let vm = this;
      vm.isLoading = true;
      if (!vm.isVendorOnboard) {
        vm.$apollo
          .query({
            query: URL_EXPIRATION,
            variables: {
              Org_Code: vm.orgCode,
              Url_Hash: vm.urlHash,
            },
            client: "apolloClientA",
            fetchPolicy: "no-cache",
          })
          .then(() => {
            vm.$emit("valid-layout");
            vm.isLoading = false;
          })
          .catch((err) => {
            vm.isLoading = false;
            if (
              err.graphQLErrors.length !== 0 &&
              err.graphQLErrors[0].extensions.code
            ) {
              if (
                err.graphQLErrors[0].extensions.code === "REO0101" ||
                err.graphQLErrors[0].extensions.code === "REO0102" ||
                err.graphQLErrors[0].extensions.code === "REO0105" ||
                err.graphQLErrors[0].extensions.code === "REO0106"
              ) {
                vm.isUrlExpired = true;
                vm.errorMessage = err.graphQLErrors[0].message;
              } else if (
                err.graphQLErrors[0].extensions.code === "REO0103" ||
                err.graphQLErrors[0].extensions.code === "REO0104"
              ) {
                vm.isSubmittedAlready = true;
                vm.errorMessage = err.graphQLErrors[0].message;
              }
            } else {
              vm.isError = true;
              vm.errorMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..";
            }
          });
      } else {
        vm.$apollo
          .query({
            query: VENDOR_URL_EXPIRATION,
            variables: {
              urlHash: vm.vendorUrlHash ? vm.vendorUrlHash : vm.urlHash,
            },
            client: "apolloClientB",
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            this.vendorId = data.data.checkUrlStatus.vendorId;
            localStorage.setItem("vendorId", this.vendorId);
            vm.$emit("is-vendor");
            vm.$emit("valid-layout");
            vm.isLoading = false;
          })
          .catch((err) => {
            vm.isLoading = false;
            if (
              err.graphQLErrors.length !== 0 &&
              err.graphQLErrors[0].extensions.code
            ) {
              if (
                err.graphQLErrors[0].extensions.code === "VO0110" ||
                err.graphQLErrors[0].extensions.code === "VO0109"
              ) {
                vm.isUrlExpired = true;
                vm.errorMessage = err.graphQLErrors[0].message;
              } else if (err.graphQLErrors[0].extensions.code === "VO0111") {
                vm.isSubmittedAlready = true;
                vm.errorMessage = err.graphQLErrors[0].message;
              }
            } else {
              vm.isError = true;
              vm.errorMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..";
            }
          });
      }
    },
  },
};
</script>
