import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default function createVuetifyInstance(colors) {
  return new Vuetify({
    icons: {
      iconfont: "md",
    },
    theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          primary: colors ? colors.Primary_Color : "#260029",
          secondary: colors ? colors.Secondary_Color : "#EC407A",
          grey: "#9E9E9E",
          green: "#4CAF50",
          deeporange: "#FF5722",
          lightblue: "#8b8d8e",
        },
        dark: {
          primary: colors ? colors.Primary_Color : "#260029",
          secondary: colors ? colors.Secondary_Color : "#EC407A",
          grey: "#9E9E9E",
          green: "#4CAF50",
          deeporange: "#FF5722",
          lightblue: "#8b8d8e",
        },
      },
    },
  });
}
