<template>
  <div class="full-height">
    <v-row class="full-height">
      <v-col
        v-if="windowWidth > 960"
        cols="6"
        :class="
          !isVendor
            ? 'login-bg white--text d-flex justify-center align-center text-h5 px-12 text-center font-weight-medium'
            : 'login-bg white--text text-h5 px-12 d-flex align-center font-weight-medium'
        "
      >
        <div v-if="!isVendor">
          <span class="text-h4 font-weight-bold pr-1">"</span
          >{{ randomWelcomeText
          }}<span class="text-h4 font-weight-bold pl-1">"</span>
        </div>
        <div v-else>
          <div class="text-h4 font-weight-bold pr-1 mb-5">
            Welcome to the {{ companyName }} Onboarding Portal
          </div>
          <div class="text-h5 font-weight-medium mb-5">
            To complete the supplier onboarding pre-qualification process;
          </div>
          <ol class="text-h5 font-weight-medium mb-5">
            <li>Complete all required fields</li>
            <li>
              Upload required documentation (e.g. Cerificates of Insurance)
            </li>
            <li>Complete the 'project resourcing spreadsheet'</li>
            <li>Review and submit</li>
          </ol>
          <div class="text-h5 font-weight-medium mb-5">
            Successful vendors will receive an automated email with login
            details and access to your own {{ companyName }} vendor dashboard.
          </div>
          <div>
            If you require any assistance, contact
            {{
              companyEmail && companyEmail !== "null"
                ? companyEmail
                : "administration"
            }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" lg="6" md="6" xl="6">
        <v-row justify="center" class="full-height">
          <v-col
            cols="12"
            sm="12"
            md="10"
            class="d-flex justify-center flex-column"
            :class="windowWidth > 960 ? '' : 'align-center'"
          >
            <div class="welcome-text my-5 font-weight-bold">
              Welcome to {{ companyName }}
            </div>
            <div class="my-10">
              <v-form @submit.prevent>
                <ValidationObserver ref="pinValidationObserver">
                  <ValidationProvider
                    ref="pinProvider"
                    v-slot="{ errors }"
                    name="Passcode"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="passcode"
                      outlined
                      label="Passcode"
                      type="password"
                      style="max-width: 400px"
                      color="primary"
                      :error-messages="errors"
                    ></v-text-field>
                    <div
                      v-if="errors.length === 0"
                      class="grey--text text--darken-2 mt-n5"
                    >
                      Please use the passcode from the welcome email.
                    </div>
                  </ValidationProvider>
                </ValidationObserver>
              </v-form>
            </div>
            <div class="my-4">
              <v-btn
                large
                color="primary"
                style="width: 100%; max-width: 400px; border-radius: 10px"
                @click="logInToApplication()"
                >Login</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <AppLoading v-if="isLoading"></AppLoading>
  </div>
</template>

<script>
import config from "@/config.js";
import { SIGN_IN, SIGN_IN_VENDOR } from "@/graphql/LoginQueries";

export default {
  name: "Login",

  props: {
    isVendor: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    companyName: "",
    companyEmail: "",
    orgCode: "",
    urlHash: "",
    passcode: "",
    isLoading: false,
  }),

  computed: {
    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    domainName() {
      return config.domain;
    },
    randomWelcomeText() {
      var r_text = new Array();
      r_text[0] =
        "We only hire people we believe will make a difference here, and we’re proud to count you among that number. We look forward to helping you make waves.";
      r_text[1] =
        "We’re thrilled that you’re bringing your unique perspective and background to our team.";
      r_text[2] =
        "You’re here because we believe in who you are and what you have to contribute.";
      return r_text[Math.floor(3 * Math.random())];
    },
  },

  mounted() {
    this.companyName = localStorage.getItem("companyName");
    this.companyEmail = localStorage.getItem("companyEmail");
    this.orgCode = localStorage.getItem("orgCode");
    this.urlHash = localStorage.getItem("urlHash");
  },

  methods: {
    logInToApplication() {
      let vm = this;
      vm.$refs.pinValidationObserver.validate().then((validationResponse) => {
        if (validationResponse) {
          vm.isLoading = true;
          let apoQuery = "",
            apoClient = "",
            variables = {};
          if (vm.isVendor) {
            apoQuery = SIGN_IN_VENDOR;
            apoClient = "apolloClientB";
            variables = {
              urlHash: vm.urlHash,
              pin: parseInt(vm.passcode),
            };
          } else {
            apoQuery = SIGN_IN;
            apoClient = "apolloClientA";
            variables = {
              Org_Code: vm.orgCode,
              Url_Hash: vm.urlHash,
              Pin: parseInt(vm.passcode),
            };
          }
          vm.$apollo
            .query({
              query: apoQuery,
              variables: variables,
              fetchPolicy: "no-cache",
              client: apoClient,
            })
            .then(() => {
              localStorage.setItem("authorization", vm.passcode);
              vm.isLoading = false;
              let snackbarData = {
                isOpen: true,
                message: "Logged in successfully.",
                type: "success",
              };
              vm.showAlert(snackbarData);
              if (vm.isVendor) {
                vm.$router.push({ name: "VendorOnboarding" });
              } else vm.$router.push({ name: "Onboarding" });
            })
            .catch((loginErr) => {
              vm.isLoading = false;
              let snackbarData = {
                isOpen: true,
                message:
                  loginErr.graphQLErrors.length !== 0
                    ? loginErr.graphQLErrors[0].message
                    : "Something went wrong. It could be a possibility due to network connectivity error..",
                type: "warning",
              };
              vm.showAlert(snackbarData);
            });
        }
      });
    },
    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>

<style lang="css">
.welcome-text {
  color: var(--v-primary-base) !important;
  font-size: 40px;
}
.login-bg {
  background: var(--v-primary-base) !important;
}
</style>
